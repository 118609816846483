@charset "utf-8";
// Fullpage css
@import "npm:fullpage.js/dist/fullpage.css";

// Font for logo + text
@import "npm:@fontsource/lato/index.css";
@import "npm:@fontsource/rubik/index.css";

// Setup fontawesome
$fa-font-path: "npm:@fortawesome/fontawesome-free/webfonts";
@import "npm:@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "npm:@fortawesome/fontawesome-free/scss/solid.scss";

// Customize Bulma
$hr-margin: 0;
@import "npm:bulma/sass/utilities/_all.sass";
@import "npm:bulma/sass/base/_all.sass";
@import "npm:bulma/sass/helpers/_all.sass";
@import "npm:bulma/sass/grid/columns.sass";

// Change navbar breakpoint
$navbar-breakpoint: $tablet;
@import "npm:bulma/sass/components/navbar.sass";
@import "npm:bulma/sass/elements/button.sass";
@import "npm:bulma/sass/elements/content.sass";
@import "npm:bulma/sass/elements/table.sass";
@import "npm:bulma/sass/elements/title.sass";
@import "block-list.scss";

// Own styles
body {
  margin: $size-7 !important;
  font-family: "Rubik", sans-serif;
}

// Font for heading
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}

// Map and FB styles
#map-iframe {
  width: 100%;
  height: 100%;
}

#fb-iframe {
  height: 100%;
}

#opening-table {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.three-quarters-height {
  height: 75%;
}

.full-height {
  height: 100%;
}

span.button {
  margin: 1rem;
}

.gallery {
  background-color: $grey-lighter;
  border-radius: $size-6;
  padding: $size-4;
  margin: $size-7;
}

.is-90 {
  max-width: 90%;
}

.is-80 {
  max-width: 80%;
}

.is-50 {
  max-width: 50%;
}

.icon-menu {
  max-width: 16px;
  -webkit-filter: grayscale(100%);
  /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(100%);
  /* FF 35+ */
}

.icon-menu:hover {
  transition: filter 0.5s ease-in-out;
  -webkit-filter: grayscale(0%);
  /* Ch 23+, Saf 6.0+, BB 10.0+ */
  filter: grayscale(0%);
  /* FF 35+ */
}

.img-title {
  max-height: 3rem;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
