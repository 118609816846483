/* rubik-arabic-400-normal */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./files/rubik-arabic-400-normal.woff2) format('woff2'), url(./files/rubik-arabic-400-normal.woff) format('woff');
  unicode-range: U+0600-06FF,U+0750-077F,U+0870-088E,U+0890-0891,U+0898-08E1,U+08E3-08FF,U+200C-200E,U+2010-2011,U+204F,U+2E41,U+FB50-FDFF,U+FE70-FE74,U+FE76-FEFC,U+102E0-102FB,U+10E60-10E7E,U+10EFD-10EFF,U+1EE00-1EE03,U+1EE05-1EE1F,U+1EE21-1EE22,U+1EE24,U+1EE27,U+1EE29-1EE32,U+1EE34-1EE37,U+1EE39,U+1EE3B,U+1EE42,U+1EE47,U+1EE49,U+1EE4B,U+1EE4D-1EE4F,U+1EE51-1EE52,U+1EE54,U+1EE57,U+1EE59,U+1EE5B,U+1EE5D,U+1EE5F,U+1EE61-1EE62,U+1EE64,U+1EE67-1EE6A,U+1EE6C-1EE72,U+1EE74-1EE77,U+1EE79-1EE7C,U+1EE7E,U+1EE80-1EE89,U+1EE8B-1EE9B,U+1EEA1-1EEA3,U+1EEA5-1EEA9,U+1EEAB-1EEBB,U+1EEF0-1EEF1;
}

/* rubik-cyrillic-ext-400-normal */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./files/rubik-cyrillic-ext-400-normal.woff2) format('woff2'), url(./files/rubik-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* rubik-cyrillic-400-normal */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./files/rubik-cyrillic-400-normal.woff2) format('woff2'), url(./files/rubik-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* rubik-hebrew-400-normal */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./files/rubik-hebrew-400-normal.woff2) format('woff2'), url(./files/rubik-hebrew-400-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* rubik-latin-ext-400-normal */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./files/rubik-latin-ext-400-normal.woff2) format('woff2'), url(./files/rubik-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* rubik-latin-400-normal */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./files/rubik-latin-400-normal.woff2) format('woff2'), url(./files/rubik-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}