@charset "utf-8";
@use "sass:math";

$block-list-separator: 0.25rem !default;
$block-list-highlight-width: 5px !default;

.content {
  ul.block-list {
    list-style: none;
    margin-left: 0;
  }
}

.block-list {
  list-style: none;

  li {
    padding: math.div($gap, 2);
    background: $light;
    margin-bottom: $block-list-separator;
  }

  li.is-left,
  &.is-left > li {
    text-align: left;
  }

  li.is-centered,
  &.is-centered > li {
    text-align: center;
  }

  li.is-right,
  &.is-right > li {
    text-align: right;
  }

  li.is-small,
  &.is-small > li {
    font-size: $small-font-size;
    padding: math.div($gap, 3);
  }

  li.is-normal,
  &.is-normal > li {
    font-size: $body-font-size;
  }

  li.is-large,
  &.is-large > li {
    font-size: $size-large;
  }

  li.has-radius,
  &.has-radius > li {
    border-radius: $radius;
  }

  li.is-highlighted,
  &.is-highlighted > li {
    border-left: $block-list-highlight-width $dark solid;
  }

  @mixin outlined($color) {
    background: transparent;
    color: $color;
    border: 1px solid $color;
  }

  li.is-outlined,
  &.is-outlined > li {
    @include outlined($border);
    color: $text;
  }

  li.has-icon {
    display: flex;

    .icon {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      margin-right: 1em;
    }

    span {
      flex-grow: 1;
    }

    &.is-right {
      .icon {
        margin-right: 0;
        margin-left: 1em;
      }
    }
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    li.is-#{$name},
    &.is-#{$name} > li {
      background: $color;
      color: $color-invert;
    }

    li.is-#{$name}.is-outlined {
      @include outlined($color);
    }

    li.is-#{$name}.is-highlighted {
      @include outlined($color);
      border-left: $block-list-highlight-width $color solid;
    }

    &.is-#{$name} {
      li.is-outlined,
      &.is-outlined > li {
        @include outlined($color);
      }

      li.is-highlighted,
      &.is-highlighted > li {
        @include outlined($color);
        border-left: $block-list-highlight-width $color solid;
      }
    }
  }
}
